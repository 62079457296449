import { useState } from "react";
import SidebarModule from "../../components/sidebar-module";
import { Link, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../redux/hooks";
import { sidebarSelector } from "../../redux/sidebar/sidebar.slice";
import {
  getCategories,
  getProjects,
} from "../../redux/settings/settings.selector";
import { ICategoryResponse, IProjectResponse } from "../../types/responses";
import Button from "components/button";
import { WhiteCrown } from "assets/icons";

const View = () => {
  const [target, setTarget] = useState<string | null>(null);
  const { show } = useAppSelector(sidebarSelector);
  const categories = useAppSelector(getCategories);
  const projects = useAppSelector(getProjects);
  const navigate = useNavigate();

  const modules = [
    {
      id: "1",
      key: "tools",
      name: "Tools",
      icon: "/threedot.svg",
      hasChild: true,
      childs: categories
        ?.map((cate: ICategoryResponse) => {
          return {
            ...cate,
            childs: projects
              .filter((pro: IProjectResponse) => pro.category_id === cate._id)
              .map((pro: IProjectResponse) => ({
                ...pro,
                path: `project/${pro._id}`,
              })),
          };
        })
        .sort(
          (prev: ICategoryResponse, next: ICategoryResponse) =>
            prev.order - next.order
        ),
      firstShow: true,
      path: "/",
      status: "enable",
    },
    {
      id: "2",
      key: "chatgpt4",
      name: "ChatGPT4",
      icon: "chat.svg",
      hasChild: false,
      firstShow: false,
      path: "/chat",
      status: "enable",
    },
    {
      id: "3",
      key: "template",
      name: "Template",
      icon: "template.svg",
      hasChild: false,
      firstShow: false,
      path: "/",
      status: "disable",
    },
    {
      id: "4",
      key: "chatpdf",
      name: "ChatPDF",
      icon: "document.svg",
      hasChild: false,
      firstShow: false,
      path: "/pdf",
      status: "enable",
    },
    {
      id: "4",
      key: "shortcast",
      name: "Shortcast",
      icon: "document.svg",
      hasChild: false,
      firstShow: false,
      path: "/shortcast",
      status: "enable",
    },
  ];

  const handleChangeTarget = (key: string) => {
    if (target !== key) {
      setTarget(key);
    }
    if (target === key) {
      setTarget(null);
    }
  };

  return (
    <>
      {show && (
        <div className="fixed z-40 flex md:top-0 opacity-100 w-full transition-all duration-300 md:sticky md:h-screen md:w-auto md:min-w-min overflow-hidden">
          <div className="relative md:w-[284px] pl-3 md:pl-8 pt-4 bg-gray-primary">
            <div className="pl-5 py-2 md:py-4 md:mb-8">
              <Link to="/">
                <img
                  src="/images/typera.ai_logo_svg.svg"
                  alt="logo"
                  className="hidden md:block w-[146px] h-9"
                />
              </Link>
            </div>
            <div className="pr-8 mt-10 md:m-0">
              {modules
                .filter((module) => module.status === "enable")
                .map((module) => (
                  <SidebarModule
                    target={target}
                    key={module.id}
                    ckey={module.key}
                    name={module.name}
                    icon={module.icon}
                    hasChild={module.hasChild}
                    childs={module.childs}
                    onTarget={(e) => handleChangeTarget(e)}
                    firstShow={module.firstShow}
                    path={module.path}
                  />
                ))}
            </div>
            <div className="absolute bottom-0 mb-3 md:mb-8 w-[calc(100%-0.75rem*2)] md:w-[calc(100%-2rem*2)]">
              <Button
                Icon={WhiteCrown}
                name="Upgrade to Pro"
                onClick={() => navigate("/subscription")}
                className="!bg-active"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default View;
