import TextArea from "components/textarea";
import Button from "components/button";
import { useState } from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import LoadingJson from "assets/loading-animation.json";

const View = ({
  data,
  onGenerate,
}: {
  data: any;
  onGenerate?: () => Promise<void>;
}) => {
  const [loading, setLoading] = useState(false);
  return (
    <div className="h-[calc(100vh-100px)] overflow-auto">
      {loading ||
        (data.summary && (
          <div className="border p-2 rounded-lg border-purple-300 bg-purple-50 max-h-[calc(100vh-100px)] overflow-auto">
            <TextArea
              value={data.summary?.trim()}
              ckey="text"
              onChange={() => {}}
              editable={false}
              className="!bg-transparent"
            />
          </div>
        ))}
      {loading ? (
        <Player autoplay loop src={LoadingJson} className="h-32" />
      ) : (
        <div className="mt-10">
          <p className="w-2/3 m-auto text-center text-icon-default">
            Select a tab to choose "Original Text", "Transcript", or "Summary",
            and then click "Generate".
          </p>
          <Button
            name="Generate"
            onClick={async () => {
              setLoading(true);
              onGenerate && (await onGenerate());
              setLoading(false);
            }}
            className="!w-1/2 m-auto mt-5 bg-typera-primary"
          />
        </div>
      )}
    </div>
  );
};

export default View;
