import MessageAction from "components/message-action";
import { addMessage, chatSelector } from "redux/chat/chat.slice";
import { v4 } from "uuid";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { ElementType, useEffect, useRef, useState } from "react";
import ChatService from "services/chat";
import ChatMessage from "components/chat-message";
import { Player } from "@lottiefiles/react-lottie-player";
import LoadingJson from "assets/loading-animation.json";
import { DraftIcon, MagicWandIcon, SearchAlt } from "assets/icons";
import { updateCount } from "redux/settings/settings.slice";
import { getAuth } from "redux/settings/settings.selector";
import { createPortal } from "react-dom";
import Toast from "components/toast";
import { MessageStatus } from "types/types";

interface IIntroduceProps {
  name: string;
  Icon: ElementType;
  descriptions: string[];
}

const Introduce = ({ name, Icon, descriptions }: IIntroduceProps) => {
  return (
    <div className="flex flex-col items-center flex-1">
      <div className="w-14 h-14 rounded-full bg-gray-primary relative">
        <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <Icon />
        </span>
      </div>
      <p className="font-medium text-sm lg:font-extramedium lg:text-base text-[#0D0133] mt-4 mb-6 text-center">
        {name}
      </p>
      <div className="flex flex-col gap-3 h-44">
        {descriptions.map((description, index) => (
          <div
            key={index}
            style={{ height: `${100 / descriptions.length}%` }}
            className={`bg-gray-primary w-full text-center rounded flex items-center`}
          >
            <p className="text-typera-secondary text-xs lg:text-sm font-medium lg:font-extramedium p-2 ">
              {description}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

const View = () => {
  const dispatch = useAppDispatch();
  const { messages: messagesChat, lock } = useAppSelector(chatSelector);
  const auth = useAppSelector(getAuth);
  const { messages, history } = messagesChat;
  const [countMessages, setCountMessages] = useState<number>(messages.length);
  const messagesRef = useRef<HTMLDivElement>(null);
  const bottomRef = useRef<HTMLDivElement>(null);
  const [isScrollingUp, setIsScrollingUp] = useState(false);
  const [allowScroll, setAllowScroll] = useState(false);
  const [message, setMessage] = useState<{
    show: boolean;
    content: string;
    type: MessageStatus | null;
  }>({
    show: false,
    content: "",
    type: null,
  });

  useEffect(() => {
    if (messages.length > 0) {
      setAllowScroll(true);
    } else {
      setAllowScroll(false);
    }
  }, [messages.length]);

  useEffect(() => {
    const messageContainer = messagesRef.current;
    const handleScroll = () => {
      if (messageContainer) {
        if (
          messageContainer?.scrollTop <
          messageContainer?.scrollHeight - messageContainer?.clientHeight
        ) {
          setIsScrollingUp(true);
        } else {
          setIsScrollingUp(false);
        }
      }
    };
    messageContainer?.addEventListener("scroll", handleScroll);

    return () => {
      messageContainer?.removeEventListener("scroll", handleScroll);
    };
  }, [allowScroll]);

  useEffect(() => {
    if (bottomRef.current && !isScrollingUp) {
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages, isScrollingUp]);

  useEffect(() => {
    if (messagesRef.current) {
      messagesRef.current.scrollTop = messagesRef.current!.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    preventScroll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lock]);

  const preventScroll = () => {
    if (messagesRef.current) {
      if (lock) {
        messagesRef.current.style.overflow = "hidden";
      } else {
        messagesRef.current.style.overflow = "auto";
      }
    }
  };

  const submitMessage = async (message: string) => {
    if (
      auth?.count.count.messages_per_day &&
      auth?.count.count.messages_per_day <= 0
    ) {
      setMessage({
        show: true,
        content:
          "You have reached the limit. Please upgrade to unlock this feature.",
        type: "warn",
      });
    } else {
      dispatch(
        addMessage({
          message: {
            id: v4(),
            type: "user",
            message,
          },
        })
      );
      if (auth?.count.count.messages_per_day !== null) {
        dispatch(
          updateCount({
            key: "messages_per_day",
            value: auth?.count.count.messages_per_day - 1,
          })
        );
      }
      setCountMessages((state) => state + 1);
      try {
        setCountMessages((state) => state + 1);
        await ChatService.chat({
          body: {
            question: message,
            history,
          },
          params: {
            // chatId: "6482dde93b3c1ad79820dfec",
          },
        });
      } catch (error) {}
    }
  };

  const handleCloseMessage = () => {
    setMessage({
      show: false,
      content: "",
      type: null,
    });
  };

  const introduces = [
    {
      id: "1",
      name: "Idea & Story Generation",
      icon: MagicWandIcon,
      descriptions: [
        '"Generate a blog post idea about emerging trends in renewable energy"',
        '"Create 5 title ideas for a blog post about vegan nutrition"',
      ],
    },
    {
      id: "2",
      name: "Draft Review & Editing",
      icon: DraftIcon,
      descriptions: [
        '"Review this paragraph for grammatical errors: [insert paragraph]"',
        '"Suggest better word choices for this sentence: [insert sentence]"',
      ],
    },
    {
      id: "3",
      name: "Content Insight Extraction",
      icon: SearchAlt,
      descriptions: [
        '"Provide a brief summary of this article: [url]"',
        '"Summarize the main points from this report: [url]"',
      ],
    },
  ];

  return (
    <>
      <div className="flex flex-col h-full lg:border-r relative">
        {messages.length === 0 && (
          <div className="relative flex-1">
            <div className="relative top-1/2 -translate-y-1/2 w-10/12 m-auto overflow-hidden">
              <div className="font-medium lg:font-extramedium text-[#222B45] text-lg text-center mt-4 w-9/12 m-auto">
                <p>
                  Welcome to our AI-powered writing assistant! Get ready to
                  boost your creativity and productivity.
                </p>
              </div>
              <div className="mt-14 flex justify-center gap-x-3">
                {introduces.map((introduce) => (
                  <Introduce
                    key={introduce.id}
                    name={introduce.name}
                    Icon={introduce.icon}
                    descriptions={introduce.descriptions}
                  />
                ))}
              </div>
            </div>
          </div>
        )}
        {messages.length > 0 && (
          <div className="flex-1">
            <div
              ref={messagesRef}
              className="relative flex-1 overflow-y-auto max-h-[calc(100vh-250px)]"
            >
              {messages.map((message: any, index: number) => (
                <ChatMessage
                  messageIndex={index}
                  message={message.message}
                  key={message.id}
                  avatar={message.avatar}
                  userId={message.userId}
                  hasAction={message.type === "ai" ? true : false}
                  type={message.type as "user" | "ai"}
                />
              ))}
              {countMessages > messages.length && (
                <Player autoplay loop src={LoadingJson} className="h-32" />
              )}
              <div ref={bottomRef} />
            </div>
          </div>
        )}
        <div className="relative bottom-8 w-full">
          <div className="w-11/12 m-auto">
            <MessageAction onSubmit={submitMessage} type="chat" />
          </div>
        </div>
      </div>
      {message.show &&
        createPortal(
          <Toast
            visible={message.show}
            message={message.content}
            onClose={handleCloseMessage}
            type={message.type || "success"}
          />,
          document.body
        )}
    </>
  );
};

export default View;
