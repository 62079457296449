import Button from "components/button";
import ChatHistoryItem from "components/chat-history-item";
import { AddIcon, CommentIcon } from "assets/icons";
import { useState } from "react";

const View = () => {
  const [targetChat, setTargetChat] = useState("");

  const chatHistories = [
    {
      id: "1",
      Icon: CommentIcon,
      content: "AI cannot Recreate Hand",
    },
    {
      id: "2",
      Icon: CommentIcon,
      content: "Did you have a specific topic in mind for our c",
    },
  ];

  const deleteChatHandler = () => {};

  const editChatHandler = () => {};

  const clickChatHandler = (id: string) => {
    setTargetChat(id);
  };

  return (
    <div>
      <div className="p-4">
        <Button
          name="New Chat"
          Icon={AddIcon}
          onClick={() => {}}
          type="fourth"
        />
      </div>
      <ul className="mx-1">
        {chatHistories.map((chat) => (
          <ChatHistoryItem
            key={chat.id}
            isTarget={targetChat === chat.id}
            ckey={chat.id}
            Icon={chat.Icon}
            content={chat.content}
            onClick={clickChatHandler}
            onDelete={deleteChatHandler}
            onEdit={editChatHandler}
          />
        ))}
      </ul>
    </div>
  );
};

export default View;
