import { Dispatch, SetStateAction, useEffect, useState } from "react";
import TextArea from "../textarea";
import Button from "../button";
import ResultService from "../../services/results";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setShow } from "../../redux/portal/portal.slice";
import {
  resultSelector,
  feedback as feedbackAction,
} from "../../redux/results/result.slice";
import { IResult } from "../../types/responses";
import { SendIcon } from "../../assets/icons";

interface IProps {
  onSubmit: Dispatch<SetStateAction<boolean>>;
}

const View = ({ onSubmit }: IProps) => {
  const dispatch = useAppDispatch();
  const { target, results } = useAppSelector(resultSelector);

  const [feedback, setFeedback] = useState({
    content: "",
  });

  useEffect(() => {
    const recursive = (array: IResult[], parent: string) => {
      for (let i = 0; i < array.length; i++) {
        if (array[i]._id === parent) {
          setFeedback({ content: array[i].feedback });
        } else {
          recursive(array[i].childs, parent);
        }
      }
    };
    recursive(results, target);
  }, [results, target]);

  const handleChangeText = (key: string, value: string) => {
    setFeedback((state) => ({
      ...state,
      [key]: value,
    }));
  };

  const handleSend = async () => {
    try {
      await ResultService.giveFeedback({
        params: {
          id: target,
        },
        body: {
          feedback: feedback.content,
        },
      });
      onSubmit(true);
      dispatch(feedbackAction({ id: target, text: feedback.content }));
      dispatch(setShow(false));
    } catch (error) {}
  };

  const handleClose = () => {
    dispatch(setShow(false));
  };

  return (
    <div className="w-96 bg-white rounded-md shadow-md absolute top-14 right-28">
      <div className="p-4">
        <h3 className="text-typera-primary font-extramedium">
          Feedback about this result
        </h3>
        <TextArea
          value={feedback.content}
          ckey="content"
          onChange={(e) => handleChangeText("content", e)}
          rows={3}
        />
        <div className="flex gap-4 mt-4">
          <Button
            name="Close"
            onClick={handleClose}
            type="thirt"
            className="flex-1"
          />
          <Button
            Icon={SendIcon}
            name="Send"
            onClick={handleSend}
            className="flex-1.5"
          />
        </div>
      </div>
    </div>
  );
};

export default View;
