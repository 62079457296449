import { BackIcon, CloudIcon } from "assets/icons";
import Button from "components/button";
import Directory from "components/directory";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import { getDirectories } from "redux/settings/settings.selector";
import { sidebarSelector } from "redux/sidebar/sidebar.slice";
import { IObject } from "types/types";

interface IProps {
  onUpload?: (e?: any) => void;
}

const View = ({ onUpload }: IProps) => {
  const { file_id } = useParams();
  const navigate = useNavigate();

  const { show } = useAppSelector(sidebarSelector);
  const directories = useAppSelector(getDirectories);
  const [targetDirectory, setTargetDirectory] = useState<any | undefined>(
    directories.find((item: any) => item.id === file_id)
  );

  useEffect(() => {
    if (file_id) {
      setTargetDirectory(directories.find((item: any) => item.id === file_id));
    }
  }, [file_id, directories]);

  return (
    <>
      {show && (
        <div className="bg-gray-primary md:bg-white fixed z-40 md:flex md:top-0 opacity-100 w-full transition-all duration-300 md:sticky md:h-screen md:w-auto md:min-w-min overflow-hidden border-r md:flex-col mt-16 md:mt-0">
          <div className="md:w-[284px] pl-3 md:pl-8 pt-4 bg-gray-primary md:bg-white">
            <div className="pl-5 py-2 md:py-4 md:mb-8">
              <Link to="/">
                <img
                  src="/images/typera.ai_logo_svg.svg"
                  alt="logo"
                  className="hidden md:block w-[146px] h-9"
                />
              </Link>
            </div>
            <div className="pr-8 mt-10 md:m-0">
              <Button
                name="Upload PDF"
                className="!rounded-lg"
                Icon={CloudIcon}
                onClick={() => {
                  onUpload && onUpload();
                }}
              />
            </div>
          </div>
          <div className="mt-5 px-4 md:w-[284px] overflow-y-auto flex-1">
            {directories &&
              directories
                ?.filter((item) => item.type !== "shortcast")
                .map((item: IObject) => (
                  <Directory
                    key={item._id}
                    id={item._id}
                    setTarget={setTargetDirectory}
                    target={targetDirectory}
                    type={item.type as "file" | "folder"}
                    name={item.name}
                    onClick={() => {
                      setTargetDirectory(item);
                    }}
                    childs={item.childs}
                    root={item}
                  />
                ))}
          </div>
          <div className="md:w-[284px] px-8 my-10 md:my-9">
            <div className="flex justify-center">
              <div className="w-24">
                <Button
                  name="Back"
                  type="fourth"
                  Icon={BackIcon}
                  onClick={() => navigate("/")}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default View;
