export enum ACTION_TYPE {
  YES = "yes",
  NO = "no",
}

export enum VARIABLE_TYPE {
  SHORT = "short",
  PARAGRAPH = "paragraph",
  DROPDOWN = "dropdown",
  LONGPARAGRAPH = "longparagraph",
}
