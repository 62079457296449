import TextArea from "components/textarea";
import Player from "react-player";

const View = ({ data }: { data: any }) => {
  return (
    <div>
      <div className="border p-2 rounded-lg border-purple-300 bg-purple-50 h-[calc(100vh-100px)] overflow-auto">
        <div className="mb-5 transcript-source-url">
          <Player url={data.url} />
        </div>
        <strong className="text-2xl">Transcript</strong>
        <TextArea
          value={data.transcript?.trim()}
          ckey="text"
          onChange={() => {}}
          editable={false}
          className="!bg-transparent"
        />
        {/* <div
      className="edit-content text-typera-primary font-normal text-sm"
      dangerouslySetInnerHTML={{ __html: data?.summary as string }}
    ></div> */}
      </div>
    </div>
  );
};

export default View;
