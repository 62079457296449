import {
  AddIcon,
  CloseIcon,
  CloseSmallIcon,
  SearchIcon,
  SuccessIcon,
} from "assets/icons";
import Action from "components/action";
import Button from "components/button";
import { useState } from "react";
import DirectoryService from "services/directories";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { addDirectory } from "redux/settings/settings.slice";
import { useNavigate } from "react-router-dom";
import { uploadToS3 } from "services/s3";
import Radio from "components/radio";
import { getAuth, getDirectories } from "redux/settings/settings.selector";
import { IDirectory } from "types/responses";
import { createPortal } from "react-dom";
import Modal from "components/modal";
import Input from "components/input";
import { FileUploader } from "react-drag-drop-files";
import Toast from "components/toast";
import { MessageStatus } from "types/types";

interface IProps {
  onClose: (e?: any) => void;
  onSubmit: (e?: any) => void;
}

const View = ({ onClose, onSubmit }: IProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const directories = useAppSelector(getDirectories);
  const [selectedGroup, setSelectedGroup] = useState<string>();
  const [selectedFile, setSelectedFile] = useState<File | null>();
  const [loading, setLoading] = useState(false);
  const [uploadPercent, setUploadPercent] = useState({
    inProgress: false,
    percent: 0,
  });
  const [newGroup, setNewGroup] = useState({
    show: false,
    name: "",
  });
  const [message, setMessage] = useState<{
    show: boolean;
    content: string;
    type: MessageStatus | null;
  }>({
    show: false,
    content: "",
    type: null,
  });
  const user = useAppSelector(getAuth);

  const selectGroupHandler = (id: string) => {
    setSelectedGroup(id);
  };

  const handleCloseMessage = () => {
    setMessage({
      show: false,
      content: "",
      type: null,
    });
  };

  const addFileToSearch = async () => {
    try {
      if (selectedFile) {
        setLoading(true);
        const uploaded = await uploadToS3(
          process.env.REACT_APP_S3_BUCKET as string,
          user?._id || "typera",
          selectedFile,
          (percent: number) => {
            if (percent > 0 && percent < 100) {
              setUploadPercent({
                inProgress: true,
                percent,
              });
            } else if (percent === 100) {
              setUploadPercent({
                inProgress: false,
                percent,
              });
            }
          }
        );
        if (uploaded) {
          try {
            const data = await DirectoryService.initSource({
              filePath: uploaded.filePath,
              key: uploaded.key,
              name: uploaded.name,
              type: uploaded.type,
              parent_id: selectedGroup,
            });
            dispatch(addDirectory(data));
            if (data) {
              onClose();
              setLoading(false);
              navigate(`/pdf/${data?.id}`);
            }
          } catch (error) {
            onClose();
            setLoading(false);
          }
        }
      }
      onSubmit();
    } catch (error) {
      console.log(error);
    }
  };

  const newGroupHandler = async () => {
    if (newGroup.name.trim()) {
      const data = await DirectoryService.create({
        body: {
          name: newGroup.name.trim(),
          type: "folder",
        },
      });
      if (data) {
        dispatch(addDirectory(data));
        setNewGroup({
          show: false,
          name: "",
        });
      }
    }
  };

  const selectFileHandler = (file: File) => {
    if (file) {
      let number_file_page = 0;
      const reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onloadend = () => {
        if (reader.result) {
          const result: any = reader.result;
          number_file_page = result.match(/\/Type[\s]*\/Page[^s]/g).length;
        }
        if (
          file.size / Math.pow(1024, 2) <= user.count?.package?.pdf_max_size &&
          number_file_page <= user.count?.package?.pdf_max_pages
        ) {
          setSelectedFile(file);
        } else {
          setMessage({
            show: true,
            content: "Please upgrade your plan to upload more size and pages",
            type: "warn",
          });
        }
      };
    }
  };

  return (
    <div className="w-4/5 lg:w-1/3 bg-white rounded-lg drop-shadow-md absolute left-1/2 -translate-x-1/2 top-10 md:top-40">
      <div className="p-6">
        <label className="font-bold text-typera-secondary text-base">
          Upload PDF
        </label>
        <div className="mt-8">
          {!selectedFile && (
            <FileUploader
              name="file"
              handleChange={selectFileHandler}
              types={["pdf", "txt"]}
            >
              <div className="flex items-center justify-center w-full">
                <label
                  htmlFor="dropzone-file"
                  className="flex flex-col items-center justify-center w-full h-40 border-2 border-gray-400 border-dashed rounded-lg cursor-pointer"
                >
                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <img src="/images/pdf.svg" alt="pdf" />
                    <p className="mb-2 mt-2 text-sm text-typera-secondary font-medium leading-6">
                      Drag and Drop file here or{" "}
                      <u className="text-typera-primary font-extramedium">
                        Choose file
                      </u>
                    </p>
                  </div>
                </label>
              </div>
            </FileUploader>
          )}
          {selectedFile && (
            <div className="bg-[#F7F9FC] rounded-lg">
              <div className="p-4">
                <div className="flex items-center gap-4 mb-3 relative">
                  <div className="inline-block rounded-lg overflow-hidden w-10 h-10">
                    <div className="bg-white flex items-center justify-center h-full w-full">
                      <img
                        src="/images/pdf.svg"
                        alt="pdf"
                        className="h-6 w-6"
                      />
                    </div>
                  </div>
                  <div className="relative w-2/3">
                    <div className="flex items-center">
                      <p className="font-medium text-sm leading-6 text-typera-secondary w-full truncate">
                        {selectedFile.name}
                      </p>
                      {uploadPercent.percent === 100 && <SuccessIcon />}
                    </div>
                    <span className={`font-normal text-xs text-icon-default`}>
                      {(selectedFile.size / (1024 * 1024)).toFixed(2)} MB
                    </span>
                  </div>
                  <div className="absolute right-0 top-0">
                    <Action
                      Icon={CloseSmallIcon}
                      onAction={() => setSelectedFile(null)}
                      id="close"
                    />
                  </div>
                </div>
                {(uploadPercent.inProgress || uploadPercent.percent > 0) && (
                  <div className="flex items-center gap-2">
                    <div className="w-full bg-gray-200 rounded-full h-1.5 dark:bg-gray-700">
                      <div
                        className="bg-[#0095FF] h-1.5 rounded-full"
                        style={{ width: `${uploadPercent.percent}%` }}
                      ></div>
                    </div>
                    <span
                      className={`font-extramedium text-[10px] ${
                        uploadPercent.percent === 100
                          ? "text-[#0095FF]"
                          : "text-icon-default"
                      }`}
                    >
                      {uploadPercent.percent}%
                    </span>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="mt-3 mb-8 flex justify-between text-xs font-normal text-icon-default">
          <p>Supported format: PDF</p>
          <p>Maximum size: {user.count?.package?.pdf_max_size}MB</p>
        </div>
        <label className="font-bold text-typera-secondary text-base">
          Upload PDF
        </label>
        <div className="mt-3 bg-[#F7F9FC] rounded-lg">
          <div className="p-4">
            <div className="flex gap-4">
              <div className="relative flex-1">
                <div className="absolute ml-4 inset-y-0 h-full text-gray-thirt flex items-center">
                  <SearchIcon />
                </div>
                <input
                  type="text"
                  className="py-3 text-sm font-medium text-black pl-12 pr-2 border-purple-100 rounded shadow-sm resize-none w-full focus:outline-none focus:ring-0"
                  placeholder={`Search group`}
                />
              </div>
              <div className="flex-1">
                <Button
                  name="Create a new group"
                  type="fourth"
                  onClick={() => setNewGroup({ show: true, name: "" })}
                  Icon={AddIcon}
                  className="!text-sm !border-none"
                />
              </div>
            </div>
            <div className="mt-4 max-h-24 overflow-y-scroll">
              {directories
                .filter((item: IDirectory) => item.type === "folder")
                .map((item: IDirectory) => (
                  <Radio
                    key={item.id}
                    id={item.id}
                    name={item.name}
                    checked={item.id === selectedGroup}
                    onCheck={selectGroupHandler}
                    className="mt-5"
                  />
                ))}
            </div>
          </div>
        </div>
        <div className="mr-0 ml-auto w-fit">
          <div className="mt-4 flex gap-3">
            <Button
              disabling={loading || uploadPercent.inProgress}
              name="Cancel"
              type="fourth"
              onClick={onClose}
              className="!w-28"
            />
            <Button
              name="Add file to search"
              loading={loading || uploadPercent.inProgress}
              onClick={addFileToSearch}
              className="!w-44"
            />
          </div>
        </div>
      </div>
      <div className="absolute top-4 right-4">
        <Action
          Icon={CloseIcon}
          id="close"
          onAction={onClose}
          disable={loading || uploadPercent.inProgress}
        />
      </div>
      {newGroup.show &&
        createPortal(
          <Modal>
            <div className="w-1/4 bg-white shadow-md p-5 pt-10 rounded-md absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
              <Input
                value={newGroup.name}
                onChange={(e) =>
                  setNewGroup((state) => ({
                    ...state,
                    name: e,
                  }))
                }
                className="w-2/3"
              />
              <div className="flex justify-center mt-5">
                <Button
                  name="Save"
                  onClick={newGroupHandler}
                  className="!w-2/3"
                />
              </div>
              <Action
                id="close"
                Icon={CloseIcon}
                onAction={() => setNewGroup({ show: false, name: "" })}
                className="!absolute top-3 right-5"
              />
            </div>
          </Modal>,
          document.body
        )}
      {message.show &&
        createPortal(
          <Toast
            visible={message.show}
            message={message.content}
            onClose={handleCloseMessage}
            type={message.type || "success"}
          />,
          document.body
        )}
    </div>
  );
};

export default View;
