import { createPortal } from "react-dom";
import Result from "../../components/result";
import { useAppSelector } from "../../redux/hooks";
import { portalSelector } from "../../redux/portal/portal.slice";
import Modal from "../../components/modal";
import Feedback from "../../components/feedback";
import { useState } from "react";
import Toast from "../../components/toast";
import { resultSelector } from "../../redux/results/result.slice";
import { Player } from "@lottiefiles/react-lottie-player";
import LoadingJson from "../../assets/loading-animation.json";
import { IProjectResponse } from "../../types/responses";
import Box from "../../components/box";

interface IProps {
  project: IProjectResponse | null;
}

const View = ({ project }: IProps) => {
  const { results, loading } = useAppSelector(resultSelector);
  const { show } = useAppSelector(portalSelector);
  const [message, setMessage] = useState(false);

  return (
    <div className="bg-gray-primary h-full">
      <div className="p-5 overflow-y-auto">
        {!loading && results.length === 0 && project?.content && (
          <Box>
            <div
              className="edit-content text-typera-primary font-normal text-sm"
              dangerouslySetInnerHTML={{ __html: project?.content as string }}
            ></div>
          </Box>
        )}
        {loading && results.length === 0 && (
          <Player autoplay loop src={LoadingJson} className="h-32" />
        )}
        {results.length > 0 &&
          results.map((result) => (
            <Result
              key={result._id}
              id={result._id}
              text={result.text}
              rating={result.rating}
              projectId={result.project_id}
              childs={result.childs}
              order={result.order}
              isDone={result.isDone}
            />
          ))}
      </div>
      {show &&
        createPortal(
          <Modal>
            <Feedback onSubmit={setMessage} />
          </Modal>,
          document.body
        )}
      {message &&
        createPortal(
          <Toast
            visible={message}
            message="Feedback Success"
            type="success"
            onClose={() => setMessage(false)}
          />,
          document.body
        )}
    </div>
  );
};

export default View;
