import { request, parseErrorResponse, IRequestData } from "./request";

const chat = (req: IRequestData) =>
  new Promise((resolve, reject) => {
    request()
      .post("chat", {
        ...req.body,
        socketId: localStorage.getItem("socketIdChat"),
        // ...req.params,
      })
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const ChatService = {
  chat,
};

export default ChatService;
