import { ThumbDown, ThumbUp } from "assets/icons";
import Action from "components/action";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "./ui/accordion";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

interface IProps {
  messageIndex: number;
  message: string;
  avatar?: string;
  hasAction: boolean;
  userId?: string;
  type?: "user" | "ai";
  sourceDocs?: any;
}

const View = ({
  messageIndex,
  message,
  userId,
  avatar,
  hasAction,
  type = "user",
  sourceDocs,
}: IProps) => {
  const renderType = () => {
    if (type === "ai") return "bg-gray-secondary";
    if (type === "user") return "bg-white";
    return "";
  };

  const thumbAction = () => {
    return (
      <div className="flex gap-x-3">
        <Action id="thumbup" Icon={ThumbUp} onAction={() => {}} />
        <Action id="thumbdown" Icon={ThumbDown} onAction={() => {}} />
      </div>
    );
  };

  return (
    <div className={`px-16 py-6 ${renderType()} border border-gray-secondary`}>
      <div className="flex gap-4">
        <img
          src={
            type === "user"
              ? "/images/avatar.png"
              : type === "ai"
              ? "/images/typera-ai-chat-icon.svg"
              : ""
          }
          alt="avatar"
          className="h-9 w-9 rounded-full"
        />
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          className="text-typera-primary text-sm"
          components={{
            code: ({ children }) => {
              return <code className="flex flex-wrap">{children}</code>;
            },
          }}
        >
          {message}
        </ReactMarkdown>
        <div className="hidden">{hasAction && thumbAction()}</div>
      </div>
      {sourceDocs && (
        <div
          className="p-5 text-typera-primary"
          key={`sourceDocsAccordion-${messageIndex}`}
        >
          <Accordion type="single" collapsible className="flex-col">
            {sourceDocs.map((doc: any, index: number) => (
              <div key={`messageSourceDocs-${index}`}>
                <AccordionItem value={`item-${index}`}>
                  <AccordionTrigger>
                    <h3>Source {index + 1}</h3>
                  </AccordionTrigger>
                  <AccordionContent>
                    <ReactMarkdown linkTarget="_blank">
                      {doc.pageContent}
                    </ReactMarkdown>
                    <p className="mt-2">
                      <b>Source:</b> {doc.metadata[`pdf.info.Title`]}
                    </p>
                  </AccordionContent>
                </AccordionItem>
              </div>
            ))}
          </Accordion>
        </div>
      )}
    </div>
  );
};

export default View;
