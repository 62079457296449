import { CloseIcon } from "assets/icons";
import Action from "components/action";
import Button from "components/button";
import Input from "components/input";
import { useState } from "react";

interface IProps {
  onClose: (e?: any) => void;
  onSubmit: (e?: any) => void;
}

const View = ({ onClose, onSubmit }: IProps) => {
  const [name, setName] = useState("");

  return (
    <div className="w-4/5 lg:w-2/5 bg-white rounded-lg drop-shadow-md absolute left-1/2 -translate-x-1/2 top-40">
      <div className="p-6">
        <label className="font-bold text-typera-secondary text-base">
          Rename Chat
        </label>
        <Input
          id="rename"
          onChange={(e) => setName(e)}
          value={name}
          className="focus:border-active mt-3"
        />
        <div className="mr-0 ml-auto w-36 mt-4">
          <Button
            name="Confirm"
            onClick={() => {
              onSubmit(name);
            }}
          />
        </div>
      </div>
      <div className="absolute top-4 right-4">
        <Action Icon={CloseIcon} id="close" onAction={onClose} />
      </div>
    </div>
  );
};

export default View;
