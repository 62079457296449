/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useId, useEffect, useMemo } from "react";
import Dropdown from "../../components/dropdown";
import Button from "../../components/button";
import { IProjectResponse } from "../../types/responses";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { generateResult } from "../../redux/results/result.action";
import {
  resultSelector,
  setLoading,
  setResults,
  targetResult,
} from "../../redux/results/result.slice";
import { createPortal } from "react-dom";
import Toast from "../../components/toast";
import TextArea from "../../components/textarea";
import { VARIABLE_TYPE } from "../../types/enums";
import Input from "../../components/input";
import { IOption, MessageStatus } from "../../types/types";
import { CreateIcon } from "../../assets/icons";
import { getAuth } from "redux/settings/settings.selector";

interface IProps {
  project: IProjectResponse | null;
}

interface IVariableInput {
  // [key: string]: string;
  [key: string]: { value: string; type: string };
}

const View = ({ project }: IProps) => {
  const eId = useId();

  const { loading } = useAppSelector(resultSelector);
  const dispatch = useAppDispatch();
  const auth = useAppSelector(getAuth);

  const [variable, setVariable] = useState<IVariableInput | null>(null);
  const [error, setError] = useState(false);
  const [tones, setTones] = useState([]);
  const [tone, setTone] = useState("");
  const [language, setLanguage] = useState("");
  const [inputErrors, setInputErrors] = useState<
    { id: string; show: boolean }[]
  >([]);
  const [outputLanguage, setOutputLanguage] = useState("");
  const [message, setMessage] = useState<{
    show: boolean;
    content: string;
    type: MessageStatus | null;
  }>({
    show: false,
    content: "",
    type: null,
  });

  useEffect(() => {
    setVariable(null);
    if (project) {
      setLanguage(
        project.languages.find((language) => language.key === "en")?.key ||
          project.languages[0].key
      );
      if (project.output_languages && project.output_languages.length > 0) {
        setOutputLanguage(project.output_languages[0].output_language);
      } else {
        setOutputLanguage("");
      }
      setVariable(
        project.variables.reduce((prev, next) => {
          return {
            ...prev,
            [next.name]: {
              value:
                next.options?.length > 0
                  ? next.options[0].value
                  : next.defaultValue || "",
              type: next.type,
            },
          };
        }, {})
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project?._id]);

  useEffect(() => {
    if (project?.tones?.hasOwnProperty(language)) {
      setTones(
        project?.tones[language].map((tone) => ({
          ...tone,
          key: tone.name,
        })) as never[]
      );
      if (project?.tones[language] && project?.tones[language].length > 0) {
        setTone(project?.tones[language][0].name as string);
      } else {
        setTone("");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  const inputErrorHandler = (id: string, show: boolean) => {
    const target = inputErrors.some((item) => item?.id === id);
    if (target) {
      setInputErrors((state) =>
        state.map((item) => (item.id === id ? { ...item, show } : item))
      );
    } else {
      setInputErrors((state) => [...state, { id, show }]);
    }
  };

  const validateVariable = () => {
    let validate = false;
    if (project?.variables.length === Object.keys(variable || {}).length) {
      for (const v in variable) {
        if (!variable[v]) {
          setError(true);
          validate = false;
          break;
        }
        if (
          !variable[v].value ||
          variable[v].value?.length < 0 ||
          variable[v].value?.length > 600
        ) {
          setError(true);
          validate = false;
          break;
        }
        setError(false);
        validate = true;
      }
    } else {
      setError(true);
    }
    return validate;
  };

  const handleCreateContent = async () => {
    if (auth?.count.count.words_per_month <= 0) {
      setMessage({
        show: true,
        content:
          "You have reached the limit. Please upgrade to unlock this feature.",
        type: "warn",
      });
    } else {
      if (!validateVariable()) {
        return;
      }
      if (project?._id) {
        dispatch(setResults([]));
        dispatch(setLoading(true));
        dispatch(
          generateResult({
            params: {
              id: project._id,
            },
            body: {
              language,
              output_language: outputLanguage,
              tone,
              variables: variable
                ? Object.keys(variable).reduce(
                    (prev, next) => ({
                      ...prev,
                      [next]: variable[next].value,
                    }),
                    {}
                  )
                : {},
            },
          })
        );
        dispatch(targetResult(""));
      }
    }
  };

  const handleInputVariable = (
    key: string,
    value: string,
    type: VARIABLE_TYPE
  ) => {
    setVariable((state) => ({
      ...state,
      [key]: {
        value,
        type,
      },
    }));
  };

  const handleCloseMessage = () => {
    setMessage({
      show: false,
      content: "",
      type: null,
    });
  };

  const invalidTones = useMemo(() => {
    let numberEmpty = 0;
    if (project?.tones && project?.languages) {
      for (let i = 0; i < project.languages.length; i++) {
        const targetLanguage = project.languages[i];
        if (
          project.tones &&
          project.tones.hasOwnProperty(targetLanguage.key) &&
          project.tones[targetLanguage.key].length === 0
        ) {
          numberEmpty += 1;
        } else {
          break;
        }
      }
    }
    return numberEmpty === project?.languages?.length;
  }, [project?.tones]);

  return (
    <div className="h-full">
      <div className="w-full border-b sticky top-0 flex items-center bg-white z-30 mt-16 md:mt-0">
        <i className="bx bxs-crown absolute text-2xl ml-5 text-yellow-300"></i>
        <div className="py-5 pl-14 bg-white">
          <div>
            <h3 className="text-2xl text-typera-primary font-bold ">
              {project?.name}
            </h3>
          </div>
        </div>
      </div>
      <div
        className="project-form p-5 overflow-y-auto"
        style={{ height: "calc(100% - 74px)" }}
      >
        <div className="mb-5">
          {(project?.variables || []).map((field) => {
            const show = inputErrors.find(
              (input) => input?.id === field.id
            )?.show;
            const description =
              field.description.charAt(0).toUpperCase() +
              field.description.slice(1);
            return (
              <div className="mt-5" key={field.id}>
                <label
                  htmlFor={`${eId}-${field}`}
                  className="flex justify-between mb-2"
                >
                  <p className="text-typera-primary font-normal text-sm">
                    {field.type !== VARIABLE_TYPE.DROPDOWN && (
                      <>
                        {description} <span className="text-red-600">*</span>
                      </>
                    )}
                    {field.type === VARIABLE_TYPE.DROPDOWN &&
                      field.options?.length > 0 && (
                        <>
                          {description} <span className="text-red-600">*</span>
                        </>
                      )}
                  </p>
                  <span
                    className={`text-xs font-medium ${
                      show ? "text-[#FFAA00]" : "text-icon-default"
                    }`}
                  >
                    {field.type !== VARIABLE_TYPE.DROPDOWN && (
                      <>
                        {(variable && variable[field.name]?.value?.length) || 0}
                        /
                        {field.type === VARIABLE_TYPE.LONGPARAGRAPH
                          ? 15000
                          : 600}
                      </>
                    )}
                  </span>
                </label>
                {field.type === VARIABLE_TYPE.SHORT && (
                  <Input
                    max={600}
                    min={0}
                    value={
                      variable && variable[field.name]
                        ? variable[field.name].value
                        : ""
                    }
                    onChange={(e) =>
                      handleInputVariable(field.name, e, VARIABLE_TYPE.SHORT)
                    }
                    onError={(e) => inputErrorHandler(field.id, e)}
                    placeholder={field.placeholder}
                    defaultValue={field.defaultValue}
                    className="bg-gray-secondary"
                  />
                )}
                {field.type === VARIABLE_TYPE.PARAGRAPH && (
                  <TextArea
                    max={600}
                    min={0}
                    ckey={field.name}
                    rows={4}
                    value={
                      variable && variable[field.name]
                        ? variable[field.name].value
                        : ""
                    }
                    onChange={(e) =>
                      handleInputVariable(
                        field.name,
                        e,
                        VARIABLE_TYPE.PARAGRAPH
                      )
                    }
                    onError={(e) => inputErrorHandler(field.id, e)}
                    placeholder={field.placeholder}
                    className="bg-gray-secondary"
                  />
                )}
                {field.type === VARIABLE_TYPE.LONGPARAGRAPH && (
                  <TextArea
                    max={15000}
                    min={0}
                    ckey={field.name}
                    rows={4}
                    value={
                      variable && variable[field.name]
                        ? variable[field.name].value
                        : ""
                    }
                    onChange={(e) =>
                      handleInputVariable(
                        field.name,
                        e,
                        VARIABLE_TYPE.LONGPARAGRAPH
                      )
                    }
                    onError={(e) => inputErrorHandler(field.id, e)}
                    placeholder={field.placeholder}
                    className="bg-gray-secondary"
                  />
                )}
                {field.type === VARIABLE_TYPE.DROPDOWN &&
                  field.options?.length > 0 && (
                    <Dropdown
                      value={
                        variable && variable[field.name]
                          ? variable[field.name].value
                          : ""
                      }
                      data={field.options}
                      ckey="2"
                      onSelect={(e) =>
                        handleInputVariable(
                          field.name,
                          e,
                          VARIABLE_TYPE.DROPDOWN
                        )
                      }
                      optionValue="value"
                      defaultValue={field?.options[0].value}
                      maxHeight={200}
                    />
                  )}
              </div>
            );
          })}
        </div>
        {project?.tones && !invalidTones && (
          <div className="mb-5">
            <label className="mb-5 text-typera-secondary text-sm">
              Tone of voice
            </label>
            <Dropdown
              value={tone}
              ckey="1"
              data={tones as IOption[]}
              onSelect={(e) => setTone(e)}
              optionValue="name"
              maxHeight={200}
            />
          </div>
        )}
        {project?.languages && project.languages.length > 1 && (
          <div className="mb-5">
            <label className="mb-5 text-typera-secondary font-normal text-sm">
              Input Languages
            </label>
            <Dropdown
              value={language}
              onSelect={(e) => setLanguage(e)}
              ckey={project._id}
              data={project.languages as unknown as IOption[]}
              defaultValue={project?.languages[0].key}
              maxHeight={200}
            />
          </div>
        )}

        {project?.output_languages && project.output_languages.length > 0 && (
          <div className="mb-5">
            <label className="mb-5 text-typera-secondary font-normal text-sm">
              Output Languages
            </label>

            <Dropdown
              value={outputLanguage}
              onSelect={(e) => setOutputLanguage(e)}
              ckey={project._id}
              data={project.output_languages as unknown as IOption[]}
              defaultValue={project?.output_languages[0]?.output_language}
              optionValue="output_language"
              maxHeight={200}
            />
          </div>
        )}

        <div>
          <Button
            name="Create Content"
            onClick={handleCreateContent}
            Icon={CreateIcon}
            loading={loading}
          />
        </div>
      </div>
      {error &&
        createPortal(
          <Toast
            type="error"
            visible={error}
            onClose={() => {
              setError(false);
            }}
            message="Please fill all fields"
          />,
          document.body
        )}
      {message.show &&
        createPortal(
          <Toast
            visible={message.show}
            message={message.content}
            onClose={handleCloseMessage}
            type={message.type || "success"}
          />,
          document.body
        )}
    </div>
  );
};

export default View;
