import { CheckIcon, PencilIcon, TrashIcon, UnCheckIcon } from "assets/icons";
import Action from "components/action";
import { ElementType, useEffect, useState } from "react";
import { Link } from "react-router-dom";

interface IProps {
  content: string;
  ckey: string;
  Icon: ElementType;
  onDelete: (e?: any) => void;
  onEdit: (id: string, key: string, value: any) => void;
  onClick: (e?: any) => void;
  isTarget?: boolean;
}

enum EAction {
  DELETE = "delete",
  EDIT = "edit",
  ACCEPT = "accept",
  REFUSE = "refuse",
}

const View = ({
  content,
  Icon,
  onDelete,
  onEdit,
  onClick,
  ckey,
  isTarget = false,
}: IProps) => {
  const [action, setAction] = useState({
    key: "",
    active: false,
  });
  const [draftContent, setDraftContent] = useState("");

  useEffect(() => {
    setAction({
      key: "",
      active: false,
    });
  }, [isTarget]);

  useEffect(() => {
    if (action.active && action.key === EAction.EDIT) {
      setDraftContent(content);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action]);

  const InternalAction = () => {
    const actions = [
      {
        id: "1",
        key: EAction.EDIT,
        icon: PencilIcon,
        handle: () =>
          setAction({
            key: EAction.EDIT,
            active: true,
          }),
        status: "enable",
        canActive: false,
      },
      {
        id: "2",
        key: EAction.DELETE,
        icon: TrashIcon,
        handle: () =>
          setAction({
            key: EAction.DELETE,
            active: true,
          }),
        status: "enable",
        canActive: false,
      },
    ];

    const subActions = [
      {
        id: "1",
        key: EAction.ACCEPT,
        icon: CheckIcon,
        handle: () => {
          if (action.key === EAction.DELETE) {
            onDelete(ckey);
            return;
          }
          if (action.key === EAction.EDIT) {
            onEdit(ckey, "name", draftContent);
            setAction({
              key: "",
              active: false,
            });
            return;
          }
        },
        status: "enable",
        canActive: false,
      },
      {
        id: "2",
        key: EAction.REFUSE,
        icon: UnCheckIcon,
        handle: () =>
          setAction({
            key: "",
            active: false,
          }),
        status: "enable",
        canActive: false,
      },
    ];

    return (
      <div className="absolute right-0 flex items-center gap-3 p-3 bg-gray-primary">
        {!action.active
          ? actions.map((action) => (
              <Action
                id={action.id}
                key={action.key}
                Icon={action.icon}
                onAction={action.handle}
                canActive={action.canActive}
              />
            ))
          : subActions.map((action) => (
              <Action
                id={action.id}
                key={action.key}
                Icon={action.icon}
                onAction={action.handle}
                canActive={action.canActive}
              />
            ))}
      </div>
    );
  };

  return (
    <Link
      to={`${ckey}`}
      className={`relative flex items-center gap-3 text-typera-secondary p-3 cursor-pointer rounded ${
        isTarget ? "bg-gray-primary" : ""
      }`}
      onClick={() => {
        onClick(ckey);
      }}
    >
      {action.active && action.key === EAction.DELETE ? (
        <span className="text-[#FF3D71]">
          <TrashIcon />
        </span>
      ) : (
        <Icon />
      )}
      <span className="w-full bg-clip-text bg-gradient-to-r from-typera-secondary text-transparent truncate font-extramedium text-sm">
        {action.active && action.key === EAction.DELETE && (
          <span>Delete "{content}"</span>
        )}
        {action.active && action.key === EAction.EDIT && (
          <input
            className="w-9/12 bg-transparent text-typera-secondary border border-[#7E53C7] outline-none"
            value={draftContent}
            onChange={(e) => setDraftContent(e.target.value)}
          />
        )}
        {action.active || content}
      </span>
      {isTarget && InternalAction()}
    </Link>
  );
};

export default View;
