interface IProps {
  id: string;
  name: string;
  checked?: boolean;
  onCheck: (e?: any) => void;
  className?: string;
}

const View = ({ id, name, checked = false, onCheck, className }: IProps) => {
  return (
    <div
      className={`flex items-center gap-3 text-typera-secondary text-sm font-medium ${className}`}
    >
      <input
        type="radio"
        name={name}
        id={id}
        checked={checked}
        onChange={() => onCheck(id)}
      />
      <label htmlFor={id}>{name}</label>
    </div>
  );
};

export default View;
