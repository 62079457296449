import { ReactNode } from "react";

interface IProps {
  children: ReactNode;
}

const View = ({ children }: IProps) => {
  return (
    <div className="border rounded-xl hover:border-active bg-white shadow-sm mb-4 cursor-pointer">
      <div className="p-5">{children}</div>
    </div>
  );
};

export default View;
