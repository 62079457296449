import { useEffect, useState } from "react";
import { wordInSequence } from "../../utils/format";
import StarRating from "../star-rating";
import Action from "../action";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setShow } from "../../redux/portal/portal.slice";
import { removed, targetResult } from "../../redux/results/result.slice";
import ResultService from "../../services/results";
import { reGenerateResult } from "../../redux/results/result.action";
import { IResult } from "../../types/responses";
import {
  BookmarkIcon,
  CopyIcon,
  MoreIcon,
  SmileIcon,
  TrashIcon,
} from "../../assets/icons";
import { createPortal } from "react-dom";
import Toast from "../toast";
import TextArea from "../textarea";
import { ACTION_TYPE } from "../../types/enums";
import type { MessageStatus } from "../../types/types";
import { Player } from "@lottiefiles/react-lottie-player";
import LoadingJson from "../../assets/loading-animation.json";
import { updateCount } from "redux/settings/settings.slice";
import { getAuth } from "redux/settings/settings.selector";

interface IProps {
  id: string;
  text: string;
  rating: number;
  projectId: string;
  childs: IResult[] | [];
  order: number;
  isDone?: boolean;
}

const View = ({
  id,
  text,
  rating,
  projectId,
  childs,
  order,
  isDone,
}: IProps) => {
  const dispatch = useAppDispatch();
  const auth = useAppSelector(getAuth);

  const [star, setStar] = useState(rating);
  const [loadChild, setLoadChild] = useState(false);
  const [message, setMessage] = useState<{
    show: boolean;
    content: string;
    type: MessageStatus | null;
  }>({
    show: false,
    content: "",
    type: null,
  });

  useEffect(() => {
    if (isDone) {
      dispatch(
        updateCount({
          key: "words_per_month",
          value:
            auth?.count.count.words_per_month - text.trim().split(" ").length,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDone]);

  const handleCopy = async () => {
    navigator.clipboard.writeText(text);
    await ResultService.action({
      params: {
        id,
      },
      body: {
        key: "copy",
        value: ACTION_TYPE.YES,
      },
    });
    setMessage({
      show: true,
      content: "Copy Success",
      type: "success",
    });
  };

  const handleRating = async (target: number) => {
    setStar(target);
    try {
      await ResultService.rating({
        params: {
          id,
        },
        body: {
          rating: target,
        },
      });
    } catch (error) {}
  };

  const handleMore = async () => {
    if (auth?.count.count.words_per_month <= 0) {
      setMessage({
        show: true,
        content:
          "Your tokens has expired, please upgrade the package to continue using",
        type: "warn",
      });
    } else {
      if (order < 3) {
        setLoadChild(true);
        try {
          await dispatch(
            reGenerateResult({
              params: {
                id,
              },
            })
          );
          await ResultService.action({
            params: {
              id,
            },
            body: {
              key: "more",
              value: ACTION_TYPE.YES,
            },
          });
          setLoadChild(false);
        } catch (error) {}
      } else {
        setMessage({
          show: true,
          content: `Only up to ${order} times`,
          type: "warn",
        });
      }
    }
  };

  const handleFeedback = () => {
    dispatch(setShow(true));
  };

  const handleBookmark = async () => {
    await ResultService.action({
      params: {
        id,
      },
      body: {
        key: "bookmark",
        value: ACTION_TYPE.YES,
      },
    });
    setMessage({
      show: true,
      content: "Save Success",
      type: "success",
    });
  };

  const handleDelete = async () => {
    await ResultService.action({
      params: {
        id,
      },
      body: {
        key: "removed",
        value: ACTION_TYPE.YES,
      },
    });
    dispatch(removed(id));
  };

  const actions = [
    {
      id: "1",
      key: "copy",
      icon: CopyIcon,
      handle: handleCopy,
      status: "enable",
      tooltip: "Copy",
      canActive: false,
    },
    {
      id: "2",
      key: "more",
      icon: MoreIcon,
      handle: handleMore,
      status: "disable",
      tooltip: "More like this",
      canActive: false,
    },
    {
      id: "3",
      key: "feedback",
      icon: SmileIcon,
      handle: handleFeedback,
      status: "enable",
      tooltip: "Feedback",
      canActive: false,
    },
    {
      id: "4",
      key: "bookmark",
      icon: BookmarkIcon,
      handle: handleBookmark,
      status: "enable",
      tooltip: "Bookmark",
      canActive: true,
    },
    {
      id: "5",
      key: "delete",
      icon: TrashIcon,
      handle: handleDelete,
      status: "enable",
      tooltip: "Delete",
      canActive: false,
    },
  ];

  const handleCloseMessage = () => {
    setMessage({
      show: false,
      content: "",
      type: null,
    });
  };

  return (
    <>
      <div
        onClick={() => dispatch(targetResult(id))}
        className="border rounded-xl hover:border-active bg-white shadow-sm mb-4 cursor-pointer"
      >
        <div className="p-5">
          <div>
            <TextArea
              value={text.trim()}
              ckey="text"
              onChange={() => {}}
              editable={false}
            />
          </div>
          <div className="flex md:flex-col lg:flex-row mt-2 justify-between">
            <div>
              <p className="text-sm text-gray-thirt">
                {wordInSequence(text)} words / {text.length} characters
              </p>
            </div>
            <div className="flex flex-col-reverse md:flex-row gap-2">
              <StarRating
                disable={!isDone}
                limit={5}
                rating={star}
                choose={handleRating}
              />
              <div className="flex gap-2">
                {actions
                  .filter((action) => action.status === "enable")
                  .map((action) => (
                    <Action
                      id={action.id}
                      key={action.key}
                      Icon={action.icon}
                      onAction={action.handle}
                      loading={action.key === "more" && loadChild}
                      tooltip={action.tooltip}
                      canActive={action.canActive}
                      disable={!isDone}
                    />
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pl-2">
        {loadChild && childs.length === 0 && (
          <Player autoplay loop src={LoadingJson} className="h-32" />
        )}
        {childs.length > 0 && (
          <div className="border-l-2 border-typera-secondary pl-2">
            {childs.map((child) => (
              <View
                key={child._id}
                id={child._id}
                text={child.text}
                rating={child.rating}
                projectId={child.project_id}
                childs={child.childs}
                order={child.order}
                isDone={child.isDone}
              />
            ))}
          </div>
        )}
      </div>
      {message.show &&
        createPortal(
          <Toast
            visible={message.show}
            message={message.content}
            onClose={handleCloseMessage}
            type={message.type || "success"}
          />,
          document.body
        )}
    </>
  );
};

export default View;
