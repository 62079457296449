import { useEffect, useState } from "react";

const View = ({ percent }: { percent: number }) => {
  const [currentPercent, setCurrentPercent] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      if (currentPercent < percent) {
        setCurrentPercent((state) => state + 1);
      }
    }, 100);
    return () => clearInterval(timer);
  }, [currentPercent, percent]);

  return (
    <div className="relative overflow-hidden border-none h-6 bg-[#dee2e6] rounded-md">
      <div
        className="flex w-full transition-all bg-[#6366F1] m-0 h-full absolute border-0 items-center justify-center overflow-hidden"
        style={{ width: `${currentPercent}%` }}
      >
        <span className="leading-6 text-white">{currentPercent}%</span>
      </div>
    </div>
  );
};

export default View;
