import { useState } from "react";
import Button from "../../components/button";
import Input from "../../components/input";
import AuthService from "../../services/auth";
import { useNavigate } from "react-router-dom";
import { GoogleIcon } from "assets/icons";
import { useQuery } from "utils/useQuery";
import { useGoogleLogin } from "@react-oauth/google";

const View = () => {
  const navigate = useNavigate();
  const query = useQuery();
  const [loading, setLoading] = useState(false);

  const [loginValue, setLoginValue] = useState({
    email: "",
    password: "",
  });

  const handleInputLogin = (key: string, value: string) => {
    setLoginValue((state) => ({
      ...state,
      [key]: value,
    }));
  };

  const handleLogin = async () => {
    setLoading(true);
    try {
      const res = await AuthService.login({ body: loginValue });
      localStorage.setItem("access_token", res.token);
      setLoading(false);
      navigate(`/?${query}`);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleLoginGoogle = useGoogleLogin({
    onSuccess: async (tokenReponse) => {
      try {
        const res = await AuthService.loginByGoogle({
          body: {
            accessToken: tokenReponse.access_token,
            type: "google",
          },
        });
        localStorage.setItem("access_token", res.token);
        setLoading(false);
        navigate(`/?${query}`);
      } catch (error) {}
    },
    onError: (err: any) => {
      setLoading(false);
    },
  });
  return (
    <div className="h-screen grid place-items-center relative bg-[#eff3f8]">
      <div
        style={{
          borderRadius: "56px",
          padding: "0.2rem",
          background:
            "linear-gradient(180deg, #170247 10%, rgba(33, 150, 243, 0) 30%)",
        }}
      >
        <div
          className="overflow-hidden bg-white"
          style={{ borderRadius: "53px" }}
        >
          <div className="p-[5rem] flex flex-col gap-5 md:w-[30rem]">
            <div className="text-center">
              <h3 className="font-bold text-3xl text-typera-primary mb-3">
                Welcome!
              </h3>
              <p className="font-semibold text-icon-default text-sm">
                Sign in to continue
              </p>
            </div>
            <div>
              <label htmlFor="email">Email</label>
              <Input
                id="email"
                type="email"
                onChange={(e) => handleInputLogin("email", e)}
                placeholder="Email address"
              />
            </div>
            <div>
              <label htmlFor="password">Password</label>
              <Input
                id="password"
                type="password"
                onChange={(e) => handleInputLogin("password", e)}
                placeholder="Password"
              />
            </div>
            <Button
              loading={loading}
              name="Sign In"
              type="primary"
              onClick={handleLogin}
            />
            <div className="grid grid-cols-1 gap-5">
              <Button
                Icon={GoogleIcon}
                loading={loading}
                name="Google"
                type="primary"
                onClick={() => {
                  setLoading(true);
                  handleLoginGoogle();
                }}
                className="!bg-white !text-typera-primary border"
              />
            </div>
            <hr />
            <p className="text-xs text-typera-primary text-center">
              Not registered?{" "}
              <span
                onClick={() => navigate("/register")}
                className="text-green-500 font-extramedium cursor-pointer"
              >
                Create an account
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default View;
