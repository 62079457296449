/* eslint-disable react-hooks/exhaustive-deps */
import Modal from "components/modal";
import SidebarPDF from "layouts/sidebar-pdf";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import Edit from "./components/edit";
import Remove from "./components/remove";
import Upload from "./components/upload";
import { getDirectoriesAsync } from "redux/settings/settings.action";
import { useAppDispatch } from "redux/hooks";
import ChatBox from "./chatbox";
import DirectoryService from "services/directories";
import { useNavigate, useParams } from "react-router-dom";
import {
  removeDirectory,
  updateDirectory,
} from "redux/settings/settings.slice";
import { useQuery } from "utils/useQuery";

const View = () => {
  const { file_id } = useParams();
  const fileInFolder = useQuery().get("file");
  const dispatch = useAppDispatch();
  const [showForm, setShowForm] = useState<{
    show: boolean;
    type: "edit" | "remove" | "upload" | null;
  }>({
    show: false,
    type: null,
  });
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getDirectoriesAsync('folder'));
  }, []);

  const closeFormHandler = () =>
    setShowForm({
      show: false,
      type: null,
    });

  const modalRender = () => {
    switch (showForm.type) {
      case "edit":
        return (
          <Edit
            onClose={closeFormHandler}
            onSubmit={async (e) => {
              try {
                if (file_id || fileInFolder) {
                  const data = await DirectoryService.update({
                    params: {
                      id: fileInFolder || (file_id as string),
                    },
                    body: {
                      name: e,
                    },
                  });
                  if (data) {
                    dispatch(
                      updateDirectory({
                        id: fileInFolder || file_id,
                        body: { name: e },
                      })
                    );
                    closeFormHandler();
                  }
                }
              } catch (error) {}
            }}
          />
        );
      case "remove":
        return (
          <Remove
            onClose={closeFormHandler}
            onSubmit={async () => {
              try {
                if (file_id || fileInFolder) {
                  await DirectoryService.deleted({
                    params: {
                      id: fileInFolder || (file_id as string),
                    },
                    query: {
                      sub: !fileInFolder,
                    },
                  });
                  dispatch(removeDirectory({ id: fileInFolder || file_id }));
                  closeFormHandler();
                  navigate(`/pdf/${file_id}`);
                }
              } catch (error) {}
            }}
          />
        );
      case "upload":
        return <Upload onClose={closeFormHandler} onSubmit={() => {}} />;
    }
  };

  return (
    <>
      <div className="flex flex-col md:flex-row">
        <SidebarPDF
          onUpload={() =>
            setShowForm({
              show: true,
              type: "upload",
            })
          }
        />
        <div className="flex flex-col w-full">
          <div className="h-screen md:h-full flex flex-col lg:flex-row">
            <ChatBox setShowForm={setShowForm} />
          </div>
        </div>
      </div>
      {showForm.show &&
        createPortal(<Modal>{modalRender()}</Modal>, document.body)}
    </>
  );
};

export default View;
