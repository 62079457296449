const View = () => {
  return (
    <div className="h-full grid place-items-center">
      <div>
        <h1 className="text-3xl md:text-5xl text-typera-secondary">
          Welcome to <span className="text-typera-primary font-extramedium">Typera</span>!
        </h1>
      </div>
    </div>
  );
};

export default View;
