import Button from "components/button";
import ChatHistoryItem from "components/chat-history-item";
import {
  AddIcon,
  BackIcon,
  CommentIcon,
  FinishIcon,
  PendingIcon,
} from "assets/icons";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { getDirectories } from "redux/settings/settings.selector";
import { IDirectory } from "types/responses";
import DirectoryService from "services/directories";
import Toast from "components/toast";
import { createPortal } from "react-dom";
import { MessageStatus } from "types/types";
import {
  removeDirectory,
  updateDirectory,
} from "redux/settings/settings.slice";

const View = ({ onNew }: { onNew?: () => void }) => {
  const { id } = useParams();
  const [targetShortcast, setTargetShortcast] = useState("");
  const directories = useAppSelector(getDirectories);
  const [message, setMessage] = useState<{
    show: boolean;
    content: string;
    type: MessageStatus | null;
  }>({
    show: false,
    content: "",
    type: null,
  });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      setTargetShortcast(id);
    }
  }, [id]);

  const deleteShortcast = async (id: string) => {
    try {
      await DirectoryService.deleted({
        params: { id },
        query: { sub: false, has_transcript: true },
      });
      dispatch(removeDirectory({ id }));
      setMessage({
        show: true,
        content: "Delete Success",
        type: "success",
      });
    } catch (error) {
      setMessage({
        show: true,
        content: "Something wen't wrong",
        type: "error",
      });
    }
  };

  const editShortcast = async (id: string, key: string, value: string) => {
    try {
      await DirectoryService.update({ params: { id }, body: { [key]: value } });
      dispatch(updateDirectory({ id, body: { [key]: value } }));
      setMessage({
        show: true,
        content: "Edit Success",
        type: "success",
      });
    } catch (error) {
      setMessage({
        show: true,
        content: "Something wen't wrong",
        type: "error",
      });
    }
  };

  const clickShortcast = (id: string) => {
    setTargetShortcast(id);
  };

  const handleCloseMessage = () => {
    setMessage({
      show: false,
      content: "",
      type: null,
    });
  };

  return (
    <div className="md:w-[284px] border-r md:h-screen">
      <div className="pl-3 md:pl-8 pt-4">
        <div className="pl-5 py-2 md:py-4 md:mb-8">
          <Link to="/">
            <img
              src="/images/typera.ai_logo_svg.svg"
              alt="logo"
              className="hidden md:block w-[146px] h-9"
            />
          </Link>
        </div>
      </div>
      <div className="p-4">
        <Button
          name="New Shortcast"
          Icon={AddIcon}
          onClick={() => {
            onNew && onNew();
          }}
          type="fourth"
        />
      </div>
      <ul className="md:w-[284px] md:h-[calc(100vh-300px)] px-2 overflow-auto">
        {directories
          ?.filter((item: IDirectory) => item.type === "shortcast")
          .map((shortcast: any) => (
            <ChatHistoryItem
              key={shortcast._id}
              isTarget={targetShortcast === shortcast._id}
              ckey={shortcast._id}
              Icon={
                shortcast.init_status === "pending"
                  ? PendingIcon
                  : shortcast.init_status === "finish"
                  ? FinishIcon
                  : CommentIcon
              }
              content={shortcast.name}
              onClick={clickShortcast}
              onDelete={deleteShortcast}
              onEdit={editShortcast}
            />
          ))}
      </ul>
      <div className="md:w-[284px] px-8 my-10 md:my-9">
        <div className="flex justify-center">
          <div className="w-24">
            <Button
              name="Back"
              type="fourth"
              Icon={BackIcon}
              onClick={() => navigate("/")}
            />
          </div>
        </div>
      </div>
      {message.show &&
        createPortal(
          <Toast
            visible={message.show}
            message={message.content}
            onClose={handleCloseMessage}
            type={message.type || "success"}
          />,
          document.body
        )}
    </div>
  );
};

export default View;
