import { Player } from "@lottiefiles/react-lottie-player";
import Button from "components/button";
import { useState } from "react";
import LoadingJson from "assets/loading-animation.json";
import DirectoryService from "services/directories";
import { MessageStatus } from "types/types";
import Toast from "components/toast";
import { createPortal } from "react-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { v4 } from "uuid";
import {
  addMessageShortcast,
  chatDirectoriesSelector,
} from "redux/chat-directories/chat-directories.slice";
import TextArea from "components/textarea";
import { twMerge } from "tailwind-merge";
import MessageAction from "components/message-action";
import { ChevronDown } from "lucide-react";

const View = ({
  target,
  onActive,
  exampleQuestions,
  activeChat = false,
}: {
  target: any;
  onActive?: () => Promise<void>;
  exampleQuestions: string[];
  activeChat?: boolean;
}) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const [toast, setToast] = useState<{
    show: boolean;
    content: string;
    type: MessageStatus | null;
  }>({
    show: false,
    content: "",
    type: null,
  });
  const { messages_shortcast: messages } = useAppSelector(
    chatDirectoriesSelector
  );
  const [showQuestions, setShowQuestions] = useState(true);

  const copyQuestionHandler = (text: string) => {
    navigator.clipboard.writeText(text);
    setToast({
      show: true,
      content: "Copy Success",
      type: "success",
    });
  };

  const chatHandler = async (chatMessage: string) => {
    try {
      if (chatMessage?.trim()) {
        dispatch(
          addMessageShortcast({
            message: {
              id: v4(),
              type: "user",
              message: chatMessage,
            },
          })
        );
        await DirectoryService.chat({
          body: {
            question: chatMessage.trim(),
            directoryId: target?.parent_id ? target.parent_id : target?.id,
            event_name: "chat-shortcast",
          },
        });
      } else {
        setToast({
          show: true,
          content: "Please fill your message",
          type: "warn",
        });
      }
    } catch (error) {
      setToast({
        show: true,
        content: "Something wen' wrong!",
        type: "error",
      });
    }
  };

  const handleCloseMessage = () => {
    setToast({
      show: false,
      content: "",
      type: null,
    });
  };

  return (
    <div>
      <div className="p-3 relative">
        <div>
          <div className="max-h-[calc(100vh-188px)] overflow-auto">
            {activeChat ? (
              <div></div>
            ) : !loading ? (
              <Button
                name="Active chat"
                onClick={async () => {
                  setLoading(true);
                  onActive && (await onActive());
                  setLoading(false);
                }}
                className="!w-1/2 m-auto my-4 !py-3 bg-typera-primary"
              />
            ) : (
              <Player autoplay loop src={LoadingJson} className="h-32" />
            )}
            <div className="mt-10">
              <ul className="overflow-auto max-h-[calc(100vh-227px)]">
                <p className="text-center">Try to ask AI those questions</p>
                <p
                  onClick={() => setShowQuestions((state) => !state)}
                  className="text-xs flex items-center cursor-pointer"
                >
                  {showQuestions ? "Hide" : "Show"} questions
                  <ChevronDown />
                </p>
                {showQuestions &&
                  exampleQuestions?.length > 0 &&
                  exampleQuestions?.map((content, index) => (
                    <li
                      key={index}
                      className="flex items-center justify-center gap-2 border-dashed border border-purple-700 rounded-lg p-4 text-typera-primary mt-3 cursor-pointer text-sm"
                      onClick={() => copyQuestionHandler(content)}
                    >
                      <span>{content}</span>
                    </li>
                  ))}
              </ul>
            </div>
            <div>
              <ul
                className={`${
                  showQuestions
                    ? "max-h-[calc(100vh-275px)]"
                    : "h-[calc(100vh-275px)]"
                } overflow-auto`}
              >
                {messages.messages.map((item: any) => (
                  <li
                    key={item.id}
                    className={twMerge(
                      "border p-2 rounded-lg w-2/3 mt-3 h-fit hover:border-active",
                      item.type === "ai" && "ml-auto"
                    )}
                  >
                    <TextArea
                      ckey="text"
                      value={item.message.trim()}
                      onChange={() => {}}
                      editable={false}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="w-full mt-5 relative">
          <div>
            <MessageAction onSubmit={chatHandler} type="shortcast" />
          </div>
        </div>
      </div>
      {toast.show &&
        createPortal(
          <Toast
            visible={toast.show}
            message={toast.content}
            onClose={handleCloseMessage}
            type={toast.type || "success"}
          />,
          document.body
        )}
    </div>
  );
};

export default View;
