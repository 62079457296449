import { Outlet, useNavigate } from "react-router-dom";
import SidebarShortcast from "layouts/sidebar-shortcast";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import Modal from "components/modal";
import Input from "components/input";
import Button from "components/button";
import { CloseIcon } from "assets/icons";
import Action from "components/action";
import Toast from "components/toast";
import { addDirectory } from "redux/settings/settings.slice";
import DirectoryService from "services/directories";
import {
  getDirectoriesAsync,
  getLanguagesAsync,
} from "redux/settings/settings.action";
import { useAppDispatch } from "redux/hooks";

const View = () => {
  const dispatch = useAppDispatch();
  const [showForm, setShowForm] = useState<"new" | null>();
  const [newShortcast, setNewShortcast] = useState("");
  const [message, setMessage] = useState<{
    show: boolean;
    type: "success" | "error" | "warn" | null;
    content: string;
  }>({
    show: false,
    type: null,
    content: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getDirectoriesAsync("shortcast"));
    dispatch(getLanguagesAsync());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseMessage = () => {
    setMessage({
      show: false,
      content: "",
      type: null,
    });
  };

  const createNewShortcastHandler = async () => {
    try {
      const data: any = await DirectoryService.create({
        body: {
          name: newShortcast.trim(),
          type: "shortcast",
        },
      });
      dispatch(addDirectory(data));
      navigate(`${data.id}`);
    } catch (error) {
      setMessage({
        show: true,
        content: "Something wen' wrong while create new shortcast",
        type: "error",
      });
    } finally {
      setShowForm(null);
    }
  };

  return (
    <>
      <div className="flex flex-col md:flex-row">
        <SidebarShortcast
          onNew={() => {
            setShowForm("new");
          }}
        />
        <div className="flex flex-col w-full">
          <div className="h-screen md:h-full flex flex-col lg:flex-row">
            <Outlet />
          </div>
        </div>
      </div>
      {showForm === "new" &&
        createPortal(
          <Modal>
            <div className="relative rounded-lg w-4/5 m-auto md:w-2/5 lg:w-1/5 bg-white mt-32">
              <div className="absolute top-3 right-3">
                <Action
                  id="close_new_modal"
                  Icon={CloseIcon}
                  onAction={() => setShowForm(null)}
                />
              </div>
              <div className="p-3">
                <p className="mb-2">Create new shortcast</p>
                <Input onChange={(e) => setNewShortcast(e)} />
                <Button
                  name="New"
                  onClick={createNewShortcastHandler}
                  className="!w-20 ml-auto mt-5"
                />
              </div>
            </div>
          </Modal>,
          document.body
        )}
      {message.show &&
        createPortal(
          <Toast
            visible={message.show}
            message={message.content}
            onClose={handleCloseMessage}
            type={message.type || "success"}
          />,
          document.body
        )}
    </>
  );
};

export default View;
