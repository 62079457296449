import { AddIcon } from "assets/icons";
import Tabs from "components/tabs";
import { useEffect, useState } from "react";
import Transcript from "./components/transcript";
import Summary from "./components/summary";
import Chat from "./components/chat";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { useParams } from "react-router-dom";
import { getDirectories } from "redux/settings/settings.selector";
import DirectoryService from "services/directories";
import { updateTranscript } from "redux/settings/settings.slice";
import { resetMessageShortcast } from "redux/chat-directories/chat-directories.slice";

const View = () => {
  const directories = useAppSelector(getDirectories);
  const { id } = useParams();
  const [targetShortcast, setTargetShortcast] = useState<any>(null);
  const transcript = targetShortcast?.transcript;
  const [activeTab, setActiveTab] = useState("transcript");
  const dispatch = useAppDispatch();

  useEffect(() => {
    setTargetShortcast(
      directories
        .filter((item) => item.type === "shortcast")
        .find((item: any) => item._id === id)
    );
    dispatch(resetMessageShortcast());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, directories, targetShortcast]);

  const generateSummary = async () => {
    try {
      const data = await DirectoryService.generateSummary({
        params: { id: targetShortcast?._id },
      });
      if (data) {
        dispatch(
          updateTranscript({
            id: transcript?._id,
            body: {
              summary: {
                content: data,
                status: true,
              },
            },
          })
        );
      }
    } catch (error) {}
  };

  const activeChatAndGenerateQuestionHandler = async () => {
    try {
      const data = await DirectoryService.activeChatAndGenerateQuestion({
        params: { id: targetShortcast?._id },
      });
      if (data) {
        dispatch(
          updateTranscript({
            id: transcript?._id,
            body: { example_questions: data, isActiveChat: true },
          })
        );
      }
    } catch (error) {}
  };

  const tabs = [
    {
      key: "transcript",
      name: "Transcript",
      icon: <AddIcon />,
      onAction: () => {
        setActiveTab("transcript");
      },
    },
    {
      key: "summary",
      name: "Summary",
      icon: <AddIcon />,
      onAction: () => {
        setActiveTab("summary");
      },
    },
  ];

  const renderActive = () => {
    switch (activeTab) {
      case "transcript": {
        return (
          <Transcript
            data={{
              url: transcript?.source,
              transcript: transcript?.transcript?.content,
            }}
          />
        );
      }
      case "summary": {
        return (
          <Summary
            onGenerate={generateSummary}
            data={{
              summary: transcript?.summary?.content,
            }}
          />
        );
      }
    }
  };

  return (
    <div className="w-full grid grid-cols-1 lg:grid-cols-12 gap-3">
      <div className="m-3 lg:col-span-7">
        <>
          <Tabs data={tabs} target={activeTab} />
          <div className="mt-2">{renderActive()}</div>
        </>
      </div>
      <div className="lg:col-span-5">
        <Chat
          target={targetShortcast}
          activeChat={transcript?.isActiveChat}
          onActive={activeChatAndGenerateQuestionHandler}
          exampleQuestions={transcript?.example_questions}
        />
      </div>
    </div>
  );
};

export default View;
