import { ArrowIcon, FileIcon, FolderIcon } from "assets/icons";
import { Dispatch, SetStateAction, useState } from "react";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "redux/hooks";
import { setShow } from "redux/sidebar/sidebar.slice";
import { IDirectory } from "types/responses";

interface IProps {
  id: string | number;
  type?: "file" | "folder";
  name: string;
  hasChild?: boolean;
  childs?: any;
  target: any;
  setTarget: Dispatch<SetStateAction<any | undefined>>;
  onClick: (e?: any, e2?: any) => void;
  root: any;
}

const View = ({
  id,
  type = "file",
  name,
  childs,
  onClick,
  setTarget,
  target,
  root,
}: IProps) => {
  const [showChild, setShowChild] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const renderChilds = () => {
    return (
      <>
        {childs?.map((item: IDirectory) => (
          <View
            key={item.id}
            id={item.id}
            type={item.type as "file" | "folder"}
            name={item.name}
            onClick={() => setTarget(item)}
            setTarget={setTarget}
            target={target}
            childs={item.childs}
            root={item}
          />
        ))}
      </>
    );
  };

  const renderWithType = () => {
    switch (type) {
      case "file":
        return (
          <>
            <FileIcon />
            <p className="w-4/5 truncate text-left">{name}</p>
          </>
        );
      case "folder":
        return (
          <>
            <span
              className={`${showChild && "rotate-90"} transition duration-300`}
            >
              <ArrowIcon />
            </span>
            <FolderIcon />
            <p className="w-2/3 truncate text-left">{name}</p>
          </>
        );
    }
  };

  return (
    <>
      <button
        className={`px-3 py-3 w-full flex items-center gap-2 rounded-md font-extramedium text-sm text-typera-secondary ${
          target?.id === id ? "bg-gray-primary" : ""
        }`}
        onClick={() => {
          onClick();
          setShowChild((state) => !state);
          // if (type === "file") {
          navigate(
            `/pdf/${
              root?.parent_id ? `${root?.parent_id}?file=${root.id}` : root.id
            }`
          );
          isMobile && dispatch(setShow(false));
          // }
        }}
      >
        {renderWithType()}
      </button>
      {showChild && <div className="ml-8">{renderChilds()}</div>}
    </>
  );
};

export default View;
