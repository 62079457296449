import { useState } from "react";
import { article } from "../../utils/format";
import { Link, useNavigate, useParams } from "react-router-dom";
import { SearchIcon } from "../../assets/icons";
import { useAppSelector } from "redux/hooks";
import { getAuth } from "redux/settings/settings.selector";
import { MessageStatus } from "types/types";
import { createPortal } from "react-dom";
import Toast from "components/toast";

interface IProps {
  target: string | null;
  ckey: string;
  name: string;
  icon?: string;
  onTarget: (e: string) => void;
  hasChild: boolean;
  childs?: any[];
  firstShow?: boolean;
  path?: string;
}

const Child = ({ target, ckey, name, onTarget, hasChild, childs }: IProps) => {
  const auth = useAppSelector(getAuth);
  const { project_id } = useParams();
  const navigate = useNavigate();
  const [message, setMessage] = useState<{
    show: boolean;
    content: string;
    type: MessageStatus | null;
  }>({
    show: false,
    content: "",
    type: null,
  });
  const isTarget: boolean = target === ckey;
  const [showChild, setShowChild] = useState(isTarget);

  const handleCloseMessage = () => {
    setMessage({
      show: false,
      content: "",
      type: null,
    });
  };

  const clickChildHandler = (target: any) => {
    if (target?.is_vip) {
      if (auth.count?.is_paid) {
        navigate(target.path);
      } else {
        setMessage({
          show: true,
          content: "Please upgrade your plan to use VIP project",
          type: "warn",
        });
      }
    } else {
      navigate(target.path);
    }
  };

  return (
    <div className="pl-2 hover:bg-gray-secondary rounded-md">
      <div
        onClick={() => {
          onTarget(ckey);
          setShowChild((state) => !state);
        }}
      >
        <div className="flex items-center cursor-pointer">
          <i
            className={`bx bxs-right-arrow mr-4 text-sm trasition-transform duration-300 ${
              showChild ? "rotate-90 text-active" : "text-typera-primary"
            }`}
          ></i>
          <p
            className={`text-xs ${
              showChild
                ? "text-active font-bold"
                : "font-medium text-typera-secondary"
            }`}
          >{`${name} (${childs?.length || 0})`}</p>
        </div>
      </div>
      {showChild && childs && hasChild && (
        <div className="mt-2.5">
          <ul>
            {childs.map((child) => {
              const active = project_id === child._id;
              return (
                <li
                  key={child._id}
                  onClick={() => clickChildHandler(child)}
                  className="rounded"
                >
                  <div
                    className={`flex items-center cursor-pointer relative ${
                      active ? "bg-[#E4E9F2]" : ""
                    }`}
                  >
                    {child?.is_vip && (
                      <i className="bx bxs-crown absolute text-sm ml-3 text-yellow-300"></i>
                    )}
                    <p className="text-xs ml-9 my-1 text-typera-secondary font-extramedium">
                      {child.name}
                    </p>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      )}
      {message.show &&
        createPortal(
          <Toast
            visible={message.show}
            message={message.content}
            onClose={handleCloseMessage}
            type={message.type || "success"}
          />,
          document.body
        )}
    </div>
  );
};

const View = ({
  target,
  ckey,
  name,
  icon,
  hasChild,
  onTarget,
  childs,
  firstShow = false,
  path = "/",
}: IProps) => {
  const isTarget: boolean = target === ckey;
  const [showChild, setShowChild] = useState(isTarget || firstShow);
  const [childTarget, setChildTarget] = useState<string | null>(null);

  const handleChangeTarget = (key: string) => {
    if (childTarget !== key) {
      setChildTarget(key);
    }
    if (childTarget === key) {
      setChildTarget(null);
    }
  };

  const renderChilds = () => {
    if (hasChild && showChild) {
      return (
        <div>
          <div className="relative mb-5 hidden">
            <div className="absolute ml-4 inset-y-0 h-full text-gray-thirt flex items-center">
              <SearchIcon />
            </div>
            <input
              type="text"
              className="py-3 text-sm text-black pl-12 pr-2 border-purple-100 rounded shadow-sm resize-none w-full focus:outline-none focus:ring-0"
              placeholder={`Find ${article(name)} ${name}`}
            />
          </div>
          {childs && (
            <div className="module-sidebar mb-9 mt-2 overflow-y-auto flex flex-col gap-3 max-h-[calc(100vh-350px)]">
              {childs.map((child) => (
                <Child
                  key={child._id}
                  ckey={child.key}
                  name={child.name}
                  onTarget={(e) => handleChangeTarget(e)}
                  target={childTarget}
                  childs={child.childs}
                  hasChild={true}
                />
              ))}
            </div>
          )}
        </div>
      );
    }
  };

  return (
    <div>
      <Link
        to={path}
        onClick={() => {
          onTarget(ckey);
          setShowChild((state) => !state);
        }}
        className="mb-2"
      >
        <div className="flex items-center text-typera-secondary py-2 px-5 hover:bg-gray-secondary cursor-pointer rounded-md">
          <img src={`/images/icons/${icon}`} alt={icon} />
          <p className="mx-2 font-bold text-base leading-6">{name}</p>
          {hasChild && (
            <i
              className={`bx bx-chevron-right text-xl trasition-transform duration-300 ${
                showChild && "rotate-90"
              }`}
            ></i>
          )}
        </div>
      </Link>
      {renderChilds()}
    </div>
  );
};

export default View;
