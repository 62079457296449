import ChatHistory from "layouts/chat-history";
import { Outlet } from "react-router-dom";

const View = () => {
  return (
    <div className="h-screen md:h-full flex flex-col lg:flex-row">
      <div className="flex-2">
        <Outlet />
      </div>
      <div className="flex-1">
        <ChatHistory />
      </div>
    </div>
  );
};

export default View;
