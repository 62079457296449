import { useState } from "react";

interface IProps {
  limit: number;
  rating: number;
  choose: (e: number) => void;
  disable?: boolean;
}

const View = ({ limit, rating, choose, disable }: IProps) => {
  const [hover, setHover] = useState(0);
  return (
    <div>
      {[...Array(limit)].map((_star, index) => {
        index += 1;
        return (
          <i
            key={index}
            className={`bx bxs-star ${
              index <= (hover || rating) ? "text-yellow-300" : "text-gray-thirt"
            } ${disable ? "cursor-not-allowed" : "cursor-pointer"}`}
            onClick={!disable ? () => choose(index) : () => {}}
            onMouseEnter={!disable ? () => setHover(index) : () => {}}
            onMouseLeave={!disable ? () => setHover(rating) : () => {}}
          ></i>
        );
      })}
    </div>
  );
};

export default View;
