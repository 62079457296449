import Action from "components/action";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { getAuth, getDirectories } from "redux/settings/settings.selector";
import Head from "layouts/head";
import { Document, Page, pdfjs } from "react-pdf";
import { GrFormPrevious, GrFormNext } from "react-icons/gr";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { Player } from "@lottiefiles/react-lottie-player";
import LoadingJson from "assets/loading-animation.json";
import { IObject, MessageStatus } from "types/types";
import { isDesktop } from "react-device-detect";
import { IDirectory } from "types/responses";
import { useQuery } from "utils/useQuery";
/* eslint-disable react-hooks/exhaustive-deps */
import ChatMessage from "components/chat-message";
import MessageAction from "components/message-action";
import {
  chatDirectoriesSelector,
  addMessage,
  addSourceIntoMessage,
  resetMessage,
} from "redux/chat-directories/chat-directories.slice";
import DirectoryService from "services/directories";
import { v4 } from "uuid";
import HeadAvatarOption from "components/head-avatar-option";
import { AiOutlineMenu } from "react-icons/ai";
import { setShow, sidebarSelector } from "redux/sidebar/sidebar.slice";
import { PencilIcon, TrashIcon } from "assets/icons";
import Toast from "components/toast";
import { createPortal } from "react-dom";
import { updateCount } from "redux/settings/settings.slice";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

// interface IProps {
//   setShowForm: (e?: any) => void;
// }

interface IProps {
  setShowForm: Dispatch<SetStateAction<any>>;
}

enum EAction {
  DELETE = "delete",
  EDIT = "edit",
  ACCEPT = "accept",
  REFUSE = "refuse",
}

const ChatBox = ({ setShowForm }: IProps) => {
  const directories = useAppSelector(getDirectories);
  const { file_id } = useParams();
  const fileInFolder = useQuery().get("file");
  const dispatch = useAppDispatch();
  const messagesRef = useRef<HTMLDivElement>(null);
  const { messages: messagesPDF, lock } = useAppSelector(
    chatDirectoriesSelector
  );
  const { messages, history } = messagesPDF;
  const [countMessages, setCountMessages] = useState<number>(0);
  const bottomRef = useRef<HTMLDivElement>(null);
  const { show } = useAppSelector(sidebarSelector);
  const [showOption, setShowOption] = useState(false);
  const navigate = useNavigate();
  const [isScrollingUp, setIsScrollingUp] = useState(false);
  const auth = useAppSelector(getAuth);
  const [message, setMessage] = useState<{
    show: boolean;
    content: string;
    type: MessageStatus | null;
  }>({
    show: false,
    content: "",
    type: null,
  });

  const target = directories
    .reduce((prev, next) => {
      let childs: any[] = [];
      if (next?.childs) {
        childs = [...next.childs];
      }
      return [...prev, next, ...childs];
    }, [] as IDirectory[])
    .find((item) => item.id === (fileInFolder || file_id));

  useEffect(() => {
    const messageContainer = messagesRef.current;
    const handleScroll = () => {
      if (messageContainer) {
        if (
          messageContainer?.scrollTop <
          messageContainer?.scrollHeight - messageContainer?.clientHeight
        ) {
          setIsScrollingUp(true);
        } else {
          setIsScrollingUp(false);
        }
      }
    };
    messageContainer?.addEventListener("scroll", handleScroll);

    return () => {
      messageContainer?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (bottomRef.current && !isScrollingUp) {
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages, isScrollingUp]);

  useEffect(() => {
    dispatch(resetMessage());
    setCountMessages(0);
    dispatch(
      addMessage({
        message: {
          id: v4(),
          type: "ai",
          message:
            "Hi! this is ChatPDF by TyperaAI, please ask me any questions",
        },
      })
    );
    setCountMessages((state) => state + 1);
  }, [file_id, fileInFolder, target]);

  useEffect(() => {
    preventScroll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lock]);

  const preventScroll = () => {
    if (messagesRef.current) {
      if (lock) {
        messagesRef.current.style.overflow = "hidden";
      } else {
        messagesRef.current.style.overflow = "auto";
      }
    }
  };

  useEffect(() => {
    if (messagesRef.current) {
      messagesRef.current.scrollTop = messagesRef.current!.scrollHeight;
    }
  }, [messages]);

  const handleShowOption = () => {
    setShowOption((state) => !state);
  };

  const options = [
    {
      id: "1",
      key: "logout",
      name: "Logout",
      icon: "logout.svg",
      handle: () => {
        localStorage.removeItem("access_token");
        navigate("/login");
      },
    },
  ];

  const groupName = () => {
    const InternalAction = () => {
      const actions = [
        {
          id: "1",
          key: EAction.EDIT,
          icon: PencilIcon,
          handle: () =>
            setShowForm({
              show: true,
              type: "edit",
            }),
          status: target?.type === "folder" ? "enable" : "disable",
          canActive: false,
        },
        {
          id: "2",
          key: EAction.DELETE,
          icon: TrashIcon,
          handle: () =>
            setShowForm({
              show: true,
              type: "remove",
            }),
          status: "enable",
          canActive: false,
        },
      ];

      return (
        <div className="absolute right-5 md:right-0 flex items-center gap-3 p-3">
          {actions
            .filter((item) => item.status === "enable")
            .map((action: any) => (
              <Action
                id={action.id}
                key={action.key}
                Icon={action.icon}
                onAction={action.handle}
                canActive={action.canActive}
              />
            ))}
        </div>
      );
    };

    return (
      <>
        <div className="bg-gray-secondary w-full h-fit border-r md:hidden fixed z-50">
          <div className="flex px-10 py-2 h-16 items-center">
            <button
              className="relative z-50 md:hidden"
              onClick={() => dispatch(setShow(!show))}
            >
              <AiOutlineMenu className="w-6 h-6" />
            </button>
            <div className="md:hidden flex w-1/2 m-auto justify-center">
              <Link to="/">
                <img
                  src="/images/typera.ai_logo_svg.svg"
                  alt="logo"
                  className="w-[146px] h-9"
                />
              </Link>
            </div>
            <div className="relative">
              <img
                src="/images/avatar.png"
                alt="avatar"
                className="w-9 h-9 rounded-full cursor-pointer"
                onClick={handleShowOption}
              />
              {showOption && (
                <div className="absolute right-0 top-12 bg-white w-20 rounded shadow-md z-50">
                  {options.map((option) => (
                    <HeadAvatarOption
                      ckey={option.key}
                      key={option.id}
                      name={option.name}
                      icon={option.icon}
                      onClick={option.handle}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="bg-gray-secondary w-full h-fit border-r mt-16 md:mt-0">
          <div className="flex md:px-16 px-8 py-2 h-16 items-center relative">
            <p className="block font-bol">Chat with {target?.name}</p>
            {InternalAction()}
          </div>
        </div>
      </>
    );
  };

  const submitMessage = async (message: string) => {
    if (
      auth.count?.count.pdf_request_per_day &&
      auth.count?.count.pdf_request_per_day <= 0
    ) {
      setMessage({
        show: true,
        content:
          "You have reached the limit. Please upgrade to unlock this feature.",
        type: "warn",
      });
    } else {
      dispatch(
        addMessage({
          message: {
            id: v4(),
            type: "user",
            message,
          },
        })
      );
      if (auth.count?.count.pdf_request_per_day !== null) {
        dispatch(
          updateCount({
            key: "pdf_request_per_day",
            value: auth.count?.count.pdf_request_per_day - 1,
          })
        );
      }
      setCountMessages((state) => state + 1);
      try {
        setCountMessages((state) => state + 1);
        const response = await DirectoryService.chat({
          body: {
            question: message,
            history,
            directoryId: target?.parent_id ? target.parent_id : target?.id,
            event_name: "chat-pdf",
          },
        });
        dispatch(addSourceIntoMessage(response));
      } catch (error) {}
      if (messagesRef.current) {
        messagesRef.current.scrollTop = messagesRef.current!.scrollHeight;
      }
    }
  };

  const handleCloseMessage = () => {
    setMessage({
      show: false,
      content: "",
      type: null,
    });
  };

  return (
    <>
      {groupName()}
      <div className="h-[calc(100vh-64px)] overflow-hidden">
        <div className="flex flex-col h-full lg:border-r relative">
          <div
            ref={messagesRef}
            className="relative flex-1 overflow-y-auto max-h-[calc(100vh-250px)]"
          >
            {messages.map((message: any, index: number) => (
              <ChatMessage
                messageIndex={index}
                message={message.message}
                key={message.id}
                avatar={message.avatar}
                userId={message.userId}
                hasAction={message.type === "ai" ? true : false}
                type={message.type as "user" | "ai"}
                sourceDocs={message?.sourceDocuments}
              />
            ))}
            {countMessages > messages.length && (
              <Player autoplay loop src={LoadingJson} className="h-32" />
            )}
            <div ref={bottomRef} />
          </div>
          <div className="relative bottom-0 w-full bg-white">
            <div className="w-11/12 m-auto py-8">
              <MessageAction onSubmit={submitMessage} type="pdf" />
            </div>
          </div>
        </div>
      </div>
      {message.show &&
        createPortal(
          <Toast
            visible={message.show}
            message={message.content}
            onClose={handleCloseMessage}
            type={message.type || "success"}
          />,
          document.body
        )}
    </>
  );
};

const View = ({ setShowForm }: IProps) => {
  const { file_id } = useParams();
  const fileInFolder = useQuery().get("file");
  const directories = useAppSelector(getDirectories);
  const [target, setTarget] = useState<IObject | null>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState<number>();

  useEffect(() => {
    if (file_id && (!target || target._id !== file_id)) {
      // setNumPages(1);
      // setPageNumber(1);
      const direc = directories
        .reduce((prev, next) => {
          let childs: any = [];
          if (next?.childs) {
            childs = next.childs;
          }
          return [...prev, next, ...childs];
        }, [] as IDirectory[])
        .find((item) => item.id === (fileInFolder || file_id));

      if (direc) {
        setTarget(direc);
      }
    }
  }, [file_id, fileInFolder, directories]);

  const pdfAction = ({ className }: { className: string }) => {
    return (
      <div
        className={`flex items-center gap-10 w-full justify-center my-3 absolute z-10 ${className}`}
      >
        <Action
          disable={pageNumber === 1}
          id="prev"
          Icon={GrFormPrevious}
          onAction={() => setPageNumber((state) => state - 1)}
        />
        <span>
          {pageNumber} / {numPages}
        </span>
        <Action
          disable={pageNumber === numPages}
          id="next"
          Icon={GrFormNext}
          onAction={() => setPageNumber((state) => state + 1)}
        />
      </div>
    );
  };

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  return (
    <>
      <div className="flex-2 lg:max-w-[80%] lg:min-w-[40%]">
        <ChatBox setShowForm={setShowForm} />
      </div>
      {isDesktop && (
        <div className="h-[calc(100vh-64px)] flex-1 lg:max-w-[40%] lg:min-w-[40%] overflow-hidden">
          <Head />
          <div className="overflow-auto h-[100%] hidden">
            <Document
              loading={
                <Player autoplay loop src={LoadingJson} className="h-32" />
              }
              file={target?.url}
              onLoadSuccess={onDocumentLoadSuccess}
              className="relative overflow-y-auto flex justify-center"
            >
              {pdfAction({ className: "top-0" })}
              <Page
                pageNumber={pageNumber}
                className="lg:max-w-[70%] lg:min-w-[70%]"
              />
              {pdfAction({ className: "bottom-0" })}
            </Document>
          </div>
        </div>
      )}
    </>
  );
};

export default View;
